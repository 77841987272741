<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Tenant</b></div>
      <b-button
        squared
        variant="primary"
        @click="$router.push('/databases/add')"
        v-if="btnAccess"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-table
              striped
              hover
              :items="items"
              :fields="fields"
              class="mt-3"
              responsive
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'action' ? '25%' : '' }"
                />
              </template>

              <template #cell(photo)="data">
                <div
                  class="
                    d-flex
                    justify-content-start
                    align-items-center
                    image-container
                  "
                >
                  <div class="image-input-wrapper" style="position: relative">
                    <img class="image" :src="data.item.photo" />
                  </div>
                </div>
              </template>

              <template #cell(is_default)="data">
                <i
                  class="fas fa-check"
                  style="color: green"
                  v-if="data.item.is_default"
                  v-b-tooltip.hover
                  title="Default"
                ></i>
                <i
                  class="fas fa-times text-danger"
                  v-if="!data.item.is_default"
                ></i>
              </template>

              <template #cell(action)="data">
                <b-button
                  v-if="data.item.is_default == 1"
                  size="sm"
                  class="mr-1 btn-primary"
                  variant="primary"
                  v-b-tooltip.hover
                  title="Default"
                  ><i class="fas fa-check px-0"></i
                ></b-button>
                <b-button
                  v-if="data.item.is_default == 0"
                  size="sm"
                  class="mr-1 btn-secondary"
                  v-b-tooltip.hover
                  title="Non Aktif"
                  @click="setDefault(data.item.id)"
                  ><i class="fas fa-times px-0"></i
                ></b-button>
                <!-- <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({
                      path: '/databases/detail/' + data.item.id,
                    })
                  "
                  ><i class="fas fa-eye px-0"></i
                ></b-button> -->
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="
                    $router.push({
                      path: '/databases/edit/' + data.item.id,
                    })
                  "
                  v-if="btnAccess"
                  ><i class="fas fa-edit px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.id)"
                  v-if="btnAccess"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import Table from "@/view/components/users/Table.vue";
import moment from "moment";

export default {
  name: "master-users",
  components: {
    Table,
  },
  data() {
    return {
      // filter
      filter: {},
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "ip",
          label: "IP",
          sortable: true,
        },
        {
          key: "is_default",
          label: "Default",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      // access
      btnAccess: true,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = ``;
      let response = await module.paginate(
        "databases",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async setDefault(id) {
      let result = await module.setDefaultDatabase(
        `databases/${id}/set-default`
      );
      if (result) {
        this.pagination();
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("databases/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Database", route: "/databases/" },
    ]);
    this.pagination();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>